<template>
    <BaseModal
        :is-visible="modalStore.activeModals['dossier-update-frequency'] === true"
        class="dossier-update-frequency-modal"
    >
        <template #title>Je volgt nu een dossier!</template>

        <template #default>
            <p>Je krijgt vanaf nu <strong>wekelijks</strong> een e-mail met daarin de laatste updates binnen de dossiers die je volgt. In <em>Mijn TLN</em> kun je je voorkeuren aanpassen.</p>
            <p>
                <BaseButton @click="toPreferencePage">
                    Pas je voorkeuren aan
                </BaseButton>
            </p>
        </template>
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
const modalStore = useModalStore();

function toPreferencePage() {
    modalStore.deactivateAllModals();
    navigateTo({ name: 'mijn-tln.dossiers' });
}
</script>

<style lang="less">
.dossier-update-frequency-modal .dn-modal__dialog {
    max-width: var(--grid-maxWidth-content);
}
</style>
